import Home from './pages/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SurveyEmrly from './pages/SurveyEmrly';
import SurveyBagay from './pages/SurveyBagey';
import About from './pages/About';
import React, { useEffect } from 'react';
import axios from 'axios';
import { Result } from 'antd';
import Loading from './components/Loading';
import Reviews from './pages/Reviews';
function App() {
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [value, setValue] = React.useState({
    survey: '',
    emirli: '',
    baiji: '',
    reviews: '',
  })
  const change = (name, value) => {
    setValue((state) => {
      return { ...state, [name]: value }
    })
  }
  useEffect(() => {
    async function getData() {
      await axios.get(`/setting/api/v1/settings/find/?key=survey`)
        .then(res => {
          change("survey", res.data.value)
        })
      await axios.get(`/setting/api/v1/settings/find/?key=emirli`)
        .then(res => {
          change("emirli", res.data.value)
        })
      await axios.get(`/setting/api/v1/settings/find/?key=reviews`)
        .then(res => {
          change("reviews", res.data.value)
        })
      await axios.get(`/setting/api/v1/settings/find/?key=baiji`)
        .then(res => {
          change("baiji", res.data.value)
          if (res.status === 200) setTimeout(() => setLoading(false), 1000)
        })
    }
    getData()
  }, [])
  return (
    <Router>
      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center justify-content-between">
          <a href="/" className="logo"><img src={require('./style/img/home/logo.png')} alt="" className="img-fluid" /></a>
          <nav id="navbar" className="navbar">
            <ul>
              <li><a className="nav-link scrollto" href="/" style={{ color: '#ef403f' }}>الرئيسية</a></li>
              <li><a className="nav-link scrollto" href="/about">من نحن</a></li>
              {value.reviews === "true" && <li><a className="nav-link scrollto" href="/reviews">تقييم الرحلات</a></li>}
              {value.survey === "true" && (value.emirli === "true" || value.baiji === "true") &&
                <li className="dropdown"><a className="link-pointer"><span>الأشتراك</span> <i className="bi bi-chevron-down me-1"></i></a>
                  <ul>
                    {value.emirli === "true" && <li><a href="/emirli">أستمارة رحلة أمرلي</a></li>}
                    {value.baiji === "true" && <li><a href="/baiji">أستمارة رحلة بيجي</a></li>}
                  </ul>
                </li>
              }

              <li className="dropdown"><a className="link-pointer"><span>المكتبة</span> <i className="bi bi-chevron-down me-1"></i></a>
                <ul>
                  <li><a href="#">الصورية</a></li>
                  <li><a href="#">المرئية</a></li>
                </ul>
              </li>
              {/* <li><a className="nav-link scrollto" href="#contact">تواصل معنا</a></li> */}
            </ul>
            <i onClick={() => setOpen(!open)} className="bi bi-list mobile-nav-toggle"></i>
          </nav>
        </div>
        <div id="sidebar" className={open ? 'sidebar active' : 'sidebar'}>
          <a href="/" className="d-flex align-items-center justify-content-center m-3"><img width={75} src={require('./style/img/home/logo.png')} alt="" className="img-fluid" /></a>
          <ul className='mt-5'>
            <li><a className="nav-link scrollto" href="/">الرئيسية</a></li>
            <li><a className="nav-link scrollto" href="/about">من نحن</a></li>
            {value.reviews === "true" && <li><a className="nav-link scrollto" href="/reviews">تقييم الرحلات</a></li>}
            {value.survey === "true" && (value.emirli === "true" || value.baiji === "true") &&
              <>
                <li className='m-0 p-0 pe-1 text-secondary' style={{ fontSize: 14 }}>الأشتراك</li>
                {value.emirli === "true" && <li><a href="/emirli">أستمارة رحلة أمرلي</a></li>}
                {value.baiji === "true" && <li><a href="/baiji">أستمارة رحلة بيجي</a></li>}
              </>
            }
            <li className='m-0 p-0 pe-1 text-secondary' style={{ fontSize: 14 }}>المكتبة</li>
            <li><a href="#">الصورية</a></li>
            <li><a href="#">المرئية</a></li>
          </ul>
        </div>
      </header>
      {loading && <Loading />}
      <div onClick={() => setOpen(false)}>
        <Routes>
          <Route
            exact={true}
            path='/'
            element={<Home survey={value.survey} emirli={value.emirli} baiji={value.baiji} />}
          />
          {value.survey === "true" && value.emirli === "true" ?
            <Route
              exact={true}
              path='/emirli'
              element={<SurveyEmrly />}
            />
            : null}

          {value.survey === "true" && value.baiji === "true" ?
            <Route
              exact={true}
              path='/baiji'
              element={<SurveyBagay value={value} />}
            />
            : null}
          {value.reviews === "true" ?
            <Route
              exact={true}
              path='/reviews'
              element={<Reviews />}
            />
            : null}
          <Route
            exact={true}
            path='/about'
            element={<About />}
          />
          <Route
            exact={true}
            path='*'
            element={<Result status="404" style={{ marginTop: 160, marginBottom: 160 }} title="404" subTitle="عذرا ، الصفحة التي قمت بزيارتها غير موجودة." />}
          />
        </Routes>
      </div>
      <footer id="footer">
        <div className="d-flex flex-column justify-content-center align-items-center  p-2">
          <div className="copyright ">
            &copy; جميع الحقوق محفوظة لدى المديرية العامة للعلاقات والخدمات الاجتماعية
          </div>
          <div className="social-links pt-3">
            {/* <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a> */}
            <a href="https://www.facebook.com/mi3raj.shuhadaa" className="facebook"><i className="bx bxl-facebook"></i></a>
            <a href="https://www.instagram.com/mi3raj.shuhadaa" className="instagram"><i className="bx bxl-instagram"></i></a>
            {/* <a href="#" className="google-plus"><i className="bx bxl-youtube"></i></a> */}
            <a href="https://t.me/mi3raj8" className="linkedin"><i className="bx bxl-telegram"></i></a>
          </div>
        </div>
      </footer>

      <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
    </Router>
  );
}

export default App;
