import { Image } from 'antd'
import React from 'react'

export default function About() {
  return (
    <div>
          <section id="about" className="hero d-flex align-items-center">

              <div className="container">
                  <div className="row   justify-content-center align-items-center">
                      <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
                          <h1 style={{ color: 'rgb(239, 64, 63)' }}>من نحن</h1>
                          <h2 style={{ fontSize: 25 }}>برنامج ثقافي ترفيهي يرعى مختلف أفراد المجتمع وفئاته بتقديمه تجربة فريدة تكسبه ما يحتاجه من المقومات المعنوية لحياة طيبة. يهدف البرنامج  لتوفير تجربة ترفيهية عائلية أمنة ثقافياً</h2>
                          <h2 style={{ fontSize: 25 }}>معراج هو برنامج ترفيهي لإحياء تراث الشهداء بطريقة مبتكرة و فريدة ، رحلة تكون فيها انت و عائلتك على ارض تحمل اسرار المعركة ، تسمع الاحداث و تعيش التجربة في جو تسامحي و ايماني يصح ان يكون انموذجاً للحياة.</h2>
                          {/* <div><a href="#survey" className="btn-get-started scrollto survey-btn ps-5 pe-5">التسجيل في رحلات معراج</a></div> */}
                      </div>
                      <div
                          className="col-lg-6 order-1 order-lg-2 d-flex align-items-center justify-content-center hero-img position-relative">
                          {/* <img
                              src={require('../style/img/home/miraj_header.png')}
                              width={450}
                              className="img-fluid  d-xl-block d-xxl-block d-lg-none d-none"
                              alt=""
                          /> */}
                          <img
                              src={require('../style/img/home/logo.png')}
                              className="img-fluid"//d-lg-block d-xl-none d-xxl-none d-block
                              width={250}
                              alt=""
                          />
                      </div>
                  </div>
              </div>

          </section>

          <main id="royatna">


 

              <section id="muhmaatna" className="testimonials">
                  <div className="container position-relative">

                      <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
                          <div className="swiper-wrapper">

                              <div className="swiper-slide">
                                  <div className="testimonial-item">
                                      <img src={require('../style/img/home/logo_w.png')} className="testimonial-img" alt="" />
                                      <h3 style={{ fontSize: 55 }}>رسالتنا</h3>
                                      <p>
                                          <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                          <span>تجربة فريدة للمجتمع، يعيش خلالها مواقف من حياة الشهداء بكل أحاسيسهم ومشاعرهم ليتوق للمكانة التي وصلوا لها فيعود الى الله. </span>
                                          <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                      </p>
                                  </div>
                              </div>
                          </div>
                          <div className="swiper-pagination"></div>
                      </div>

                  </div>
              </section>

              <section id="qymna" className="counts">
                  <div className="container">

                      <div className="text-center title">
                          <h3 style={{ fontSize: 55, color: 'rgb(239, 64, 63)' }}>رؤيتنا</h3>
                          <h3>مجتمع يرى الحياة بمنظار الشهداء</h3>
                      </div>
                  </div>
              </section>
          </main>

    </div>
  )
}
