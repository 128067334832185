import React from 'react'

export default function Loading() {
    return (
        <div className='loading'>
            <div className='loading-image'>
                <img src={require('../style/img/home/logo_w.png')} width={300} />
                <p className='fs-1'>برنامج معراج</p>
            </div>
        </div>
    )
}
